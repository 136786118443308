import fileDownload from "js-file-download";

export const setRegistratieStats = function(state, payload) {
  state.registratieStats = payload;
};

export const setNewRapportage = function(state, payload) {
  // state.rapportages.push(payload);
  fileDownload(payload, "rapportage.xls");
};
