import Vue from "vue";

const hoofdletter = Vue.mixin({
  methods: {
    hoofdletter(payload) {
      return payload.charAt(0).toUpperCase() + payload.slice(1);
    }
  }
});

export default hoofdletter;
