<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$router.replace({
      name: "meldformulier-seksuele-intimidatie-gemeenten"
    });
  }
};
</script>
