<style scoped>
.vizierblue {
  background: #24315f !important;
}
</style>

<template>
  <div>
    <div></div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("meldformulieren/getSettings");
  }
};
</script>
