import Vue from "vue";
import VueRouter from "vue-router";
import App from "../views/App.vue";
import Home from "@/App.vue";
import Login from "../views/Auth/Login.vue";
import FourZeroFour from "../views/FourZeroFour.vue";

import Passwordreset from "../views/Auth/Passwordreset.vue";
import PasswordChange from "../views/Auth/PasswordChange.vue";
import UserSettings from "@/views/Auth/UserSettings.vue";

import Dashboard from "../views/Dashboard.vue";

import GebruikersBase from "../views/Gebruikers/GebruikersBase.vue";
import GebruikersIndex from "../views/Gebruikers/GebruikersIndex.vue";
import GebruikerToevoegen from "../views/Gebruikers/GebruikerToevoegen.vue";
import GebruikerAanpassen from "../views/Gebruikers/GebruikerAanpassen.vue";

import RegistratiesBase from "@/views/Registraties/RegistratiesBase.vue";
import RegistratiesIndex from "@/views/Registraties/RegistratiesIndex.vue";
import RegistratieAanmaken from "@/views/Registraties/RegistratieAanmaken.vue";
import RegistratieAanpassen from "@/views/Registraties/RegistratieAanpassen.vue";

import RapportagesBase from "@/views/Rapportages/RapportagesBase.vue";
import RapportagesIndex from "@/views/Rapportages/RapportagesIndex.vue";

import LogboekBase from "@/views/Logboek/LogboekBase.vue";
import LogboekIndex from "@/views/Logboek/LogboekIndex.vue";

import MeldBase from "@/views/Melden/MeldenBase.vue";
import MeldRedirect from "@/views/Melden/MeldRedirect.vue";
import MeldFormulierGemeenten from "@/views/Melden/MeldformulierGemeenten.vue";
import MeldingSucces from "@/views/Melden/MeldingSucces.vue";
import MeldformulierSexueleIntimidatieArnhem from "@/views/Melden/MeldformulierSexueleIntimidatieArnhem.vue";
import MeldformulierSexueleIntimidatieEnschede from "@/views/Melden/MeldformulierSexueleIntimidatieEnschede.vue";
import MeldformulierSexueleIntimidatieOverig from "@/views/Melden/MeldformulierSexueleIntimidatieOverig.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/meldformulier/sexuele-intimidatie",
    component: MeldRedirect,
    name: "redirect-to-seksuele-inimidatie"
  },
  {
    path: "/meldformulier/seksuele-intimidatie/",
    component: MeldBase,
    children: [
      {
        path: "",
        component: MeldFormulierGemeenten,
        name: "meldformulier-seksuele-intimidatie-gemeenten"
      },
      {
        path: "gemeente-arnhem",
        component: MeldformulierSexueleIntimidatieArnhem,
        name: "meldformulier-seksuele-intimidatie-arnhem"
      },
      {
        path: "gemeente-enschede",
        component: MeldformulierSexueleIntimidatieEnschede,
        name: "meldformulier-seksuele-intimidatie-enschede"
      },
      {
        path: "andere-gemeente",
        component: MeldformulierSexueleIntimidatieOverig,
        name: "meldformulier-seksuele-intimidatie-andere-gemeente"
      }
    ]
  },
  {
    path: "/meldformulier/meldingverstuurd",
    component: MeldBase,
    children: [
      {
        path: "",
        component: MeldingSucces,
        name: "meldingsuccess"
      }
    ]
  },
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "",
        name: "inloggen",
        component: Login
      },
      {
        path: "wachtwoord-resetten",
        name: "wachtwoord-resetten",
        component: Passwordreset
      },
      {
        path: "wachtwoord-resetten/:email/:token",
        name: "wachtwoord-veranderen",
        component: PasswordChange
      },
      {
        path: "app",
        component: App,
        children: [
          {
            path: "",
            name: "app",
            component: Dashboard
          },
          {
            path: "instellingen",
            name: "instellingen",
            component: UserSettings
          },
          {
            path: "registraties",
            component: RegistratiesBase,
            children: [
              {
                path: "",
                component: RegistratiesIndex,
                name: "registraties-index"
              },
              {
                path: "aanmaken",
                component: RegistratieAanmaken,
                name: "registratie-aanmaken"
              },
              {
                path: ":registratie/aanpassen",
                component: RegistratieAanpassen,
                name: "registratie-aanpassen"
              }
            ]
          },
          {
            path: "rapportages",
            component: RapportagesBase,
            children: [
              {
                path: "",
                component: RapportagesIndex,
                name: "rapportages-index"
              }
            ]
          },
          {
            path: "logboek",
            component: LogboekBase,
            children: [
              {
                path: "",
                component: LogboekIndex,
                name: "logboek-index"
              }
            ]
          },
          {
            path: "gebruikers",
            component: GebruikersBase,
            children: [
              {
                path: "",
                component: GebruikersIndex,
                name: "gebruikers-index"
              },
              {
                path: "toevoegen",
                component: GebruikerToevoegen,
                name: "gebruiker-toevoegen"
              },
              {
                path: ":gebruiker/aanpassen",
                component: GebruikerAanpassen,
                name: "gebruiker-aanpassen"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: "*",
    component: FourZeroFour,
    name: "404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
  routes
});

export default router;
