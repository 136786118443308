<template>
  <div>
    <section class="hero is-info is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-4">
              <div class="container box">
                <form @submit.prevent="loginButtonClicked()">
                  <span
                    class="has-text-centered title is-3"
                    style="color:black;"
                    >Wachtwoord resetten</span
                  >
                  <br />
                  <br />

                  <b-field
                    label="Email"
                    :type="{ 'is-danger': validation.email }"
                    :message="{ [validation.email]: validation.email }"
                  >
                    <b-input
                      v-model="form.email"
                      rounded
                      placeholder="Emailadres"
                    ></b-input>
                  </b-field>

                  <b-button
                    native-type="submit"
                    :loading="ajaxloading.sendingresetpasswordemail"
                    type="is-primary is-rounded"
                    >Versturen</b-button
                  >
                  <p class="help">
                    <router-link tag="a" :to="{ name: 'inloggen' }"
                      >Terug naar inloggen</router-link
                    >
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {}
    };
  },
  computed: {},
  methods: {
    loginButtonClicked() {
      this.$store.dispatch("auth/resetPassword", this.form).then(() => {
        this.$store.dispatch("createFlashBanner", {
          message:
            "Email verstuurd. Volg de instructies in de mail om verder te gaan.",
          timeout: 5
        });
        this.$router.push({ name: "inloggen" });
      });
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
