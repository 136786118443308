<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="users-title">Registraties</div>

    <div class="block table-icons">
      <b-tooltip label="Registratie aanmaken" position="is-top">
        <span class="pointer table-icon" @click="registratieAanmaken()">
          <b-icon icon="plus"></b-icon>
        </span>
      </b-tooltip>
      <b-tooltip
        v-if="selected"
        label="Registratie aanpassen"
        position="is-top"
      >
        <span class="pointer table-icon" @click="registratieAanpassen()">
          <b-icon icon="edit"></b-icon>
        </span>
      </b-tooltip>

      <b-tooltip
        label="Registratie verwijderen"
        v-if="selected && selected['status'] !== 'afgehandeld'"
        position="is-top"
      >
        <span class="pointer table-icon" @click="registratieVerwijderen()">
          <b-icon icon="trash"></b-icon>
        </span>
      </b-tooltip>
    </div>

    <br />
    <br />
    <div>
      <b-tabs v-model="selectedTab">
        <b-loading v-model="ajaxloading.gettingRegistraties" />
        <b-tab-item label="Registraties in behandeling">
          <b-table
            sortable
            :data="huidigeRegistraties"
            :bordered="true"
            :striped="true"
            :paginated="true"
            :per-page="10"
            :pagination-simple="true"
            :pagination-position="'top'"
            :pagination-rounded="true"
            :hoverable="true"
            :loading="false"
            :focusable="false"
            :mobile-cards="false"
            :selected.sync="selected"
            @dblclick="registratieAanpassen()"
          >
            <b-table-column
              sortable
              field="id"
              label="ID"
              width="40"
              numeric
              v-slot="props"
            >
              {{ props.row.id }}
            </b-table-column>

            <b-table-column sortable field="bron" label="Bron" v-slot="props">
              {{ props.row.bron }}
            </b-table-column>

            <b-table-column
              sortable
              field="datum_melding"
              label="Datum melding"
              v-slot="props"
            >
              {{ props.row.datum_melding }}
            </b-table-column>

            <b-table-column
              sortable
              field="datum_incident"
              label="Datum incident"
              v-slot="props"
            >
              {{ props.row.datum_incident }}
            </b-table-column>

            <b-table-column
              sortable
              field="gemeente"
              label="Gemeente"
              v-slot="props"
            >
              {{ hoofdletter(props.row.gemeente) }}
            </b-table-column>

            <b-table-column
              sortable
              field="contact_opnemen"
              label="Contact opnemen"
              v-slot="props"
            >
              <span v-if="props.row.contact_opnemen">ja</span>
              <span v-if="!props.row.contact_opnemen">nee</span>
            </b-table-column>

            <b-table-column
              sortable
              field="meldingvoor"
              label="Melder"
              v-slot="props"
            >
              <span
                v-if="
                  props.row.meldingvoor === 'melding gemaakt door persoon zelf'
                "
                >zelf</span
              >
              <span
                v-if="props.row.meldingvoor === 'melding gemaakt door derde'"
                >derde</span
              >
            </b-table-column>

            <b-table-column
              sortable
              field="incident"
              label="Incident"
              v-slot="props"
            >
              {{ props.row.incident }}
            </b-table-column>

            <b-table-column
              field="status"
              sortable
              label="Status"
              v-slot="props"
            >
              {{ props.row.status }}
            </b-table-column>
          </b-table>
        </b-tab-item>
        <b-tab-item label="Afgehandelde registraties">
          <b-table
            :data="afgehandeldeRegistraties"
            :bordered="true"
            :striped="true"
            :hoverable="true"
            :paginated="true"
            :per-page="50"
            :pagination-simple="true"
            :pagination-position="'top'"
            :pagination-rounded="true"
            :loading="false"
            :focusable="false"
            :mobile-cards="false"
            :selected.sync="selected"
            @dblclick="registratieAanpassen()"
          >
            <b-table-column
              field="id"
              label="ID"
              width="40"
              numeric
              v-slot="props"
              sortable
            >
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="bron" sortable label="Bron" v-slot="props">
              {{ props.row.bron }}
            </b-table-column>

            <b-table-column
              field="datum_melding"
              label="Datum melding"
              v-slot="props"
              sortable
            >
              {{ props.row.datum_melding }}
            </b-table-column>

            <b-table-column
              sortable
              field="datum_incident"
              label="Datum incident"
              v-slot="props"
            >
              {{ props.row.datum_incident }}
            </b-table-column>

            <b-table-column
              sortable
              field="gemeente"
              label="Gemeente"
              v-slot="props"
            >
              {{ hoofdletter(props.row.gemeente) }}
            </b-table-column>

            <b-table-column
              sortable
              field="contact_opnemen"
              label="Contact opnemen"
              v-slot="props"
            >
              <span v-if="props.row.contact_opnemen">ja</span>
              <span v-if="!props.row.contact_opnemen">nee</span>
            </b-table-column>

            <b-table-column
              sortable
              field="meldingvoor"
              label="Melder"
              v-slot="props"
            >
              <span
                v-if="
                  props.row.meldingvoor === 'melding gemaakt door persoon zelf'
                "
                >zelf</span
              >
              <span
                v-if="props.row.meldingvoor === 'melding gemaakt door derde'"
                >derde</span
              >
            </b-table-column>

            <b-table-column
              sortable
              field="incident"
              label="Incident"
              v-slot="props"
            >
              {{ props.row.incident }}
            </b-table-column>

            <b-table-column
              sortable
              field="status"
              label="Status"
              v-slot="props"
            >
              {{ props.row.status }}
            </b-table-column>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
      selectedTab: 0
    };
  },
  computed: {
    registraties() {
      return this.$store.getters["registraties/registraties"];
    },
    huidigeRegistraties() {
      return this.registraties.filter(a => a.status !== "afgehandeld");
    },
    afgehandeldeRegistraties() {
      return this.registraties.filter(a => a.status === "afgehandeld");
    }
  },
  methods: {
    registratieAanmaken() {
      this.$router.push({ name: "registratie-aanmaken" });
    },
    registratieAanpassen() {
      this.$router.push({
        name: "registratie-aanpassen",
        params: { registratie: this.selected.id }
      });
    },
    registratieVerwijderen() {
      this.$buefy.dialog.confirm({
        title: "Gebruiker archiveren",
        message:
          'Weet je zeker dat je registratie "' +
          this.selected.id +
          '" wil verwijderen?',
        confirmText: "Verwijderen",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("registraties/registratieVerwijderen", this.selected)
            .then(() => {
              this.selected = null;
            })
      });
    },
    getAfgehandeldeRegistraties() {
      this.$store.dispatch("registraties/getRegistraties");
    }
  },
  watch: {
    selectedTab: function(val) {
      if (this.selectedTab === 1) {
        this.getAfgehandeldeRegistraties();
      }
    }
  },
  mounted() {
    this.customMounted("registraties/getRegistratiesInbehandeling");
  }
};
</script>
