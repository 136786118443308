import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import buefy from "buefy";
// import "buefy/dist/buefy.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowUp,
  faEdit,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faPlus,
  faTimes,
  faTrash,
  faUser,
  faUserSlash,
  faCalendar,
  faClock,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faEye,
  faEyeSlash,
  faExclamationCircle,
  faTrash,
  faTimes,
  faArrowUp,
  faArrowDown,
  faPlus,
  faEdit,
  faArchive,
  faUser,
  faUserSlash,
  faCalendar,
  faAngleRight,
  faAngleLeft,
  faClock,
  faTimesCircle
);

Vue.component("icon", FontAwesomeIcon);

import { axiosrequest } from "@/globals/interceptors.js";
import { axiosresponse } from "@/globals/interceptors.js";

import vuemixins from "@/globals/mixins/mixins.js";
Vue.mixin(vuemixins);

Vue.config.productionTip = false;
Vue.use(buefy, {
  defaultIconComponent: "icon",
  defaultIconPack: "fas",
  customIconPacks: {
    // fas: {
    //   sizes: {
    //     default: "2x",
    //     "is-small": "1x",
    //     "is-medium": "2x",
    //     "is-large": "3x"
    //   },
    //   iconPrefix: ""
    // }
  }
});

import routerMiddleware from "@/globals/middleware/beforeEach";
router.beforeEach(routerMiddleware);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
