import axios from "axios";

export const axiosApi = function({ rootGetters, commit }, payload) {
  const token = rootGetters["auth/token"];
  const user = rootGetters["auth/user"];

  commit("loadingOn", payload.loading);
  return axios({
    url: process.env.VUE_APP_API_URL + "/api/" + payload.url,
    method: payload.method,
    data: payload.data,
    headers: {
      Authorization: "Bearer " + user.id + " " + token
    }
  })
    .then(function(response) {
      commit("loadingOff", payload.loading);
      return Promise.resolve(response);
    })
    .catch(function(error) {
      commit("loadingOff", payload.loading);
      return Promise.reject(error);
    });
};

export const loadingOn = function({ commit }, payload) {
  commit("loadingOn", payload);
};

export const loadingOff = function({ commit }, payload) {
  commit("loadingOff", payload);
};

export const axiosWeb = function({ commit }, payload) {
  commit("loadingOn", payload.loading);
  return axios({
    url: process.env.VUE_APP_API_URL + "/web/" + payload.url,
    method: payload.method,
    data: payload.data,
    withCredentials: true
  })
    .then(function(response) {
      commit("loadingOff", payload.loading);
      return Promise.resolve(response);
    })
    .catch(function(error) {
      commit("loadingOff", payload.loading);
      return Promise.reject(error);
    });
};

export const setValidationErrors = function({ commit }, payload) {
  commit("setValidationErrors", payload);
};

export const clearValidationErrors = function({ commit }) {
  commit("setValidationErrors", {});
};

export const createFlashBanner = function({ commit, dispatch }, payload) {
  var message = String;
  var timeout = Number;

  if (typeof payload === "string") {
    message = payload;
    timeout = 2500;
  } else {
    message = payload.message;
    timeout = payload.timeout * 1000;
  }

  commit("setFlashBanner", message);
  setTimeout(function() {
    dispatch("destroyFlashBanner");
  }, timeout);
};

export const destroyFlashBanner = function({ commit }, payload) {
  commit("setFlashBanner", null);
};
