<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div>
    <div>
      <div class="box container">
        <div class="users-title">Gebruiker {{ gebruiker.name }} aanpassen</div>
        <br />
        <br />
        <br />
        <form @submit.prevent="gebruikerAanpassen()">
          <b-field
            label="Naam"
            :type="{ 'is-danger': validation.naam }"
            :message="{
              [validation.naam]: validation.naam
            }"
          >
            <b-input rounded placeholder="Naam" v-model="form.naam"> </b-input>
          </b-field>

          <div class="field">
            <b-checkbox v-model="form.password_change">
              Wachtwoord veranderen
            </b-checkbox>
          </div>

          <span v-if="form.password_change">
            <b-field
              label="Huidig password"
              :type="{ 'is-danger': validation.huidig_password }"
              :message="{
                [validation.huidig_password]: validation.huidig_password
              }"
            >
              <b-input
                type="password"
                rounded
                placeholder="Huidig password"
                password-reveal
                v-model="form.huidig_password"
              >
              </b-input>
            </b-field>

            <b-field
              label="Nieuw password"
              :type="{ 'is-danger': validation.nieuw_password }"
              :message="{
                [validation.nieuw_password]: validation.nieuw_password
              }"
            >
              <b-input
                type="password"
                rounded
                placeholder="Nieuw password"
                password-reveal
                v-model="form.nieuw_password"
              >
              </b-input>
            </b-field>

            <b-field
              label="Nieuw password herhalen"
              :type="{ 'is-danger': validation.nieuw_password_confirmation }"
              :message="{
                [validation.nieuw_password_confirmation]:
                  validation.nieuw_password_confirmation
              }"
            >
              <b-input
                type="password"
                rounded
                placeholder="Nieuw password herhalen"
                password-reveal
                v-model="form.nieuw_password_confirmation"
              >
              </b-input>
            </b-field>
          </span>

          <b-button
            native-type="submit"
            :loading="ajaxloading.gebruikerAanpassen"
            type="is-primary is-rounded"
            >Gebruiker aanpassen</b-button
          >&nbsp;

          <b-button type="is-rounded" @click="$router.go(-1)"
            >Annuleren</b-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        naam: "",
        id: ""
      }
    };
  },
  computed: {
    gebruiker() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    gebruikerAanpassen() {
      this.$store.dispatch("auth/gebruikerAanpassen", this.form).then(() => {
        this.$store.dispatch("createFlashBanner", {
          message: "Uw instellingen zijn aangepast.",
          timeout: 3
        });
        this.$router.push({ name: "app" });
      });
    }
  },
  watch: {
    gebruiker: function(val) {
      this.form.naam = this.gebruiker.name;
      this.form.id = this.gebruiker.id;
    }
  },
  mounted() {
    this.form.naam = this.gebruiker.name;
    this.form.id = this.gebruiker.id;
  }
};
</script>

<style scoped>
.centered-box {
  min-width: 800px;
}
</style>
