<style scoped>
.vizierblue {
  background: #24315f !important;
}

.box {
  padding: 40px;
  margin-bottom: 30px;
  background-color: #f0f0f5;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

h5 {
  padding: 0px;
  margin: 0px;
}
</style>

<template>
  <div>
    <nav class="navbar is-primary vizierblue">
      <div class="navbar-start container">
        <span class="navbar-item" style="color:white;">
          Vizieroost | Meldformulier voor seksuele intimidatie
        </span>
      </div>
    </nav>

    <br />
    <br />
    <div class="container is-fluid">
      <div class="content has-text-centered">
        <h3>Alvast bedankt voor het melden! <br>Uw inzet draagt bij aan verandering</h3>
        <hr>
        <h5>Kies hieronder uw gemeente</h5>
      </div>
      <br />
      <div
        class="box centered-content content pointer"
        @click="arnhemClicked()"
      >
        <h5 class="centered-content">Gemeente Arnhem</h5>
      </div>

      <div
        class="box centered-content content pointer"
        @click="enschedeClicked()"
      >
        <h5 class="centered-content">Gemeente Enschede</h5>
      </div>

      <div
        class="box centered-content content pointer"
        @click="overigClicked()"
      >
        <h5 class="centered-content">Andere gemeente</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {
    arnhemClicked() {
      this.$router.push({ name: "meldformulier-seksuele-intimidatie-arnhem" });
    },
    enschedeClicked() {
      this.$router.push({
        name: "meldformulier-seksuele-intimidatie-enschede"
      });
    },
    overigClicked() {
      this.$router.push({
        name: "meldformulier-seksuele-intimidatie-andere-gemeente"
      });
    }
  },
  mounted() {}
};
</script>
