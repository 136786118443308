export const getSettings = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-settings";
  data.method = "get";
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      commit("setSettings", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const submitFormSeksueleIntimidatieGemeenteArnhem = function(
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "submit-form-seksuele-intimidatie-gemeente-arnhem";
  data.method = "POST";
  data.loading = "formulier_arnhem_versturen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const submitFormSeksueleIntimidatieGemeenteEnschede = function(
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "submit-form-seksuele-intimidatie-gemeente-enschede";
  data.method = "POST";
  data.loading = "formulier_enschede_versturen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const submitFormSeksueleIntimidatieAndereGemeente = function(
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "submit-form-seksuele-intimidatie-andere-gemeente";
  data.method = "POST";
  data.loading = "formulier_andere_gemeente_versturen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};
