<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}
</style>

<template>
  <div>
    <div class="box container">
      <div class="users-title">Rapportages</div>

      <br />
      <br />
      <div>
        <form action="" @submit.prevent="rapportageAanmaken()">
          <b-field id="datum_incident" label="Van">
            <b-datepicker
              size="is-small"
              v-model="form.begindatum"
              locale="nl-NL"
              rounded
              placeholder="Selecteer begindatum van rapportage"
              icon="calendar"
              :first-day-of-week="1"
            >
            </b-datepicker>
          </b-field>
          <b-field id="datum_incident" label="Tot">
            <b-datepicker
              size="is-small"
              v-model="form.einddatum"
              locale="nl-NL"
              rounded
              placeholder="Selecteer einddatum van rapportage"
              icon="calendar"
              :first-day-of-week="1"
            >
            </b-datepicker>
          </b-field>

          <b-field
            id="gemeente"
            label="Gemeente"
            :type="{ 'is-danger': validation.gemeente }"
            :message="validation.gemeente"
          >
            <b-autocomplete
              @input="setWijk()"
              id="gemeente"
              v-model="form.gemeente"
              :data="filteredGemeentes"
              placeholder="Gemeente"
              clearable
              rounded
              size="is-small"
              :keep-first="true"
              :open-on-focus="true"
              @select="option => (selected = option)"
            >
              <template #empty>Geen gemeentes gevonden</template>
            </b-autocomplete>
            <!-- <b-input
              id="gemeente"
              custom-class="backgroundGrey"
              placeholder="Gemeente"
              v-model="form.gemeente"
            ></b-input> -->
          </b-field>

          <b-field
            v-if="form.gemeente === 'Arnhem' || form.gemeente === 'Enschede'"
            id="wijk"
            label="Wijk"
            :type="{ 'is-danger': validation.wijk }"
            :message="validation.wijk"
          >
            <b-autocomplete
              id="wijk"
              v-model="form.wijk"
              :data="filteredWijken"
              placeholder="Kies wijk"
              clearable
              rounded
              size="is-small"
              :keep-first="true"
              :open-on-focus="true"
              @select="option => (selected = option)"
            >
              <template #empty>Geen wijken gevonden</template>
            </b-autocomplete>
            <!-- <b-input
              id="gemeente"
              custom-class="backgroundGrey"
              placeholder="Gemeente"
              v-model="form.gemeente"
            ></b-input> -->
          </b-field>

          <br />

          <b-button
            v-if="gemeenten.length || user.rol === 'superuser'"
            native-type="submit"
            :loading="ajaxloading.rapportageaanmaken"
            type="is-primary is-rounded"
            >Rapportage maken</b-button
          >
          <span v-else>Gebruiker is niet gemachtigd voor rapportages</span>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        wijk: ""
      },
      gemeenten: [],
      wijken: []
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    settings() {
      return this.$store.getters["auth/settings"];
    },
    filteredGemeentes() {
      if (!this.gemeenten.length) {
        return this.gemeenten;
      }
      if (!this.form.gemeente) {
        return this.gemeenten;
      } else {
        return this.gemeenten.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.form.gemeente.toLowerCase()) >= 0
          );
        });
      }
    },
    filteredWijken() {
      if (!this.gemeenten.length) {
        return [];
      }
      if (!this.form.gemeente) {
        return [];
      } else {
        return this.wijken.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.form.wijk.toLowerCase()) >= 0
          );
        });
      }
    }
  },
  methods: {
    setWijk() {
      if (this.settings && this.form.gemeente) {
        const gemeenten = Object.keys(this.settings.registraties.wijken);
        if (gemeenten.includes(this.form.gemeente.toLowerCase())) {
          this.wijken = [];
          const array = this.settings.registraties.wijken[
            this.form.gemeente.toLowerCase()
          ];
          const newArray = array.filter(a => a.length > 0);
          newArray.unshift("Alle wijken");
          this.wijken = newArray;

          if (!this.wijken.includes(this.form.wijk)) {
            this.form.wijk = "";
          }
        }
      }
    },
    rapportageAanmaken() {
      this.$store.dispatch("rapportages/aanmaken", this.form);
    },
    checkAuthorizedGemeenten() {
      if (this.user.rapportages_arnhem || this.user.rol === "superuser") {
        this.gemeenten.push("Arnhem");
      }
      if (this.user.rapportages_enschede || this.user.rol === "superuser") {
        this.gemeenten.push("Enschede");
      }
      if (
        this.user.rapportages_overige_gemeente ||
        this.user.rol === "superuser"
      ) {
        this.gemeenten.push("Overige gemeente");
      }
    }
  },
  created() {
    this.form.begindatum = new Date(new Date().getFullYear(), 0, 1);
    this.form.einddatum = new Date(new Date().getFullYear(), 11, 31);
  },
  watch: {
    form: {
      deep: true,
      handler() {}
    },
    user: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler() {
        this.checkAuthorizedGemeenten();
      }
    }
  },
  mounted() {
    // this.customMounted("logboek/getUsers");
    this.checkAuthorizedGemeenten();
  }
};
</script>
