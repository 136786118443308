<style scoped>
.vizierblue {
  background: #24315f !important;
}

.backgroundGrey {
  background-color: #f3f4f6 !important;
}

.pinkTitle {
  color: #e83e8c;
}

.pinkButton {
  background-color: #e83e8c;
  color: white;
}

.button-text-padding {
  padding-left: 25px;
  padding-right: 25px;
}

.margin-select-columns {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

#contact_opnemen {
  padding-bottom: 8px;
}

#leeftijd {
  margin-bottom: 8px;
}

#gebruik_melding_communicatie {
  margin-bottom: 8px;
}

.centrumseksueeelgeweldbox {
  background-color: #f8d7da;
  color: #944d4d;
}

.centrumseksueeelgeweldbox a {
  color: #284f91;
}

#locatie_omschrijving {
  margin-top: 0px !important;
}

.outerLayer {
  padding-bottom: 64px !important;
}

button:disabled {
  background: #e83e8c;
}
</style>

<template>
  <div>
    <nav class="navbar is-primary vizierblue">
      <div class="navbar-start container">
        <span class="navbar-item" style="color: white">
          Vizieroost | Meldformulier voor seksuele intimidatie voor andere
          gemeente
        </span>
      </div>
    </nav>
    <br />
    <br />
    <!-- form -->
    <div class="container is-fluid outerLayer">
      <a href="https://www.meldseksueleintimidatie.nl/"
        ><button class="button is-rounded is-warning">
          Melding annuleren
        </button></a
      >
      <br />
      <br />
      <div class="columns">
        <div class="column is-5">
          <b-field>
            <b-radio
              id="meldingvoor"
              v-model="form.meldingvoor"
              size="is-small"
              native-value="melding gemaakt door persoon zelf"
            >
              Ik meld voor mezelf
            </b-radio>
          </b-field>
          <b-field>
            <b-radio
              v-model="form.meldingvoor"
              size="is-small"
              native-value="melding gemaakt door derde"
            >
              Ik meld voor een ander
            </b-radio>
          </b-field>

          <!-- Contactinformatie -->
          <h1 class="title">Contactinformatie:</h1>
          <b-field>
            <b-checkbox id="anoniem" v-model="form.anoniem" size="is-small"
              >Ik wil anoniem melden</b-checkbox
            >
          </b-field>

          <b-field
            v-if="!form.anoniem"
            :type="{ 'is-danger': validation.naam }"
            :message="validation.naam"
            custom-class="backgroundGrey"
          >
            <b-input
              id="naam"
              custom-class="backgroundGrey"
              placeholder="Je-naam"
              v-model="form.naam"
            ></b-input>
          </b-field>

          <b-field v-if="!form.anoniem">
            <b-checkbox
              id="contact_opnemen"
              v-model="form.contact_opnemen"
              size="is-small"
              >Ik wil contact over deze melding met een
              meldpunt-consulent</b-checkbox
            >
          </b-field>

          <b-field
            v-if="form.contact_opnemen"
            :type="{ 'is-danger': validation.telefoonnummer }"
            :message="validation.telefoonnummer"
          >
            <b-input
              id="telefoonnummer"
              custom-class="backgroundGrey"
              placeholder="Telefoonnummer"
              v-model="form.telefoonnummer"
            ></b-input>
          </b-field>

          <b-field
            v-if="form.contact_opnemen"
            :type="{ 'is-danger': validation.email }"
            :message="validation.email"
          >
            <b-input
              id="email"
              custom-class="backgroundGrey"
              type="email"
              placeholder="E-mail adres"
              v-model="form.email"
            ></b-input>
          </b-field>

          <b-field
            :type="{ 'is-danger': validation.leeftijd }"
            :message="validation.leeftijd"
          >
            <b-input
              id="leeftijd"
              custom-class="backgroundGrey"
              placeholder="Leeftijd"
              v-model="form.leeftijd"
            ></b-input>
          </b-field>
          <br />

          <div class="columns">
            <div class="column margin-select-columns">
              <b-field>
                <b-radio
                  id="geslacht"
                  v-model="form.geslacht"
                  size="is-small"
                  native-value="vrouw"
                >
                  Vrouw
                </b-radio>
              </b-field>
            </div>
            <div class="column margin-select-columns">
              <b-field>
                <b-radio
                  v-model="form.geslacht"
                  size="is-small"
                  native-value="man"
                >
                  Man
                </b-radio>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column margin-select-columns">
              <b-field>
                <b-radio
                  v-model="form.geslacht"
                  size="is-small"
                  native-value="anders"
                >
                  Anders
                </b-radio>
              </b-field>
            </div>
            <div class="column margin-select-columns">
              <b-field>
                <b-radio
                  v-model="form.geslacht"
                  size="is-small"
                  native-value="wil ik niet zeggen"
                >
                  Wil ik niet zeggen
                </b-radio>
              </b-field>
            </div>
          </div>

          <div>
            We maken graag gebruik van jouw ervaring, om andere mensen te
            informeren over de impact van seksuele intimidatie, en om het melden
            van seksuele intimidatie te bemoedigen. Als dit akkoord voor jou is,
            dan nemen we contact met je op, om afspraken te maken over hoe we
            jouw ervaring kunnen delen: anoniem of niet, en welke details er
            verteld mogen worden, en welke niet. We zullen nooit zonder overleg
            en zonder jouw toestemming je verhaal delen. Maar delen helpt wel!
          </div>

          <br />

          <b-field>
            <b-checkbox
              id="gebruik_melding_communicatie"
              v-model="form.gebruik_melding_communicatie"
              >Deze melding mag wel worden gebruikt als ervaringsverhaal op de
              website en/of in andere media, door het meldpunt seksuele
              intimidatie.</b-checkbox
            >
          </b-field>

          <div class="box centrumseksueeelgeweldbox">
            Bij aanranding of verkrachting: bel direct de politie via 112, vraag
            hulp en doe aangifte. Of bel centrum seksueel geweld 0800-0188 of
            chat via
            <a
              href="https://www.centrumseksueelgeweld.nl"
              target="_blank"
              rel="noopener noreferrer"
              >www.centrumseksueelgeweld.nl</a
            >
          </div>
        </div>

        <!-- Incident -->
        <div class="column is-7 box">
          <div class="title pinkTitle">Over incident:</div>
          <div class="title is-5">Wat is er gebeurd?</div>
          <div class="columns">
            <div class="column is-4 margin-select-columns">
              <b-field>
                <b-radio
                  id="incident"
                  v-model="form.incident"
                  size="is-small"
                  native-value="naroepen"
                >
                  Naroepen
                </b-radio>
              </b-field>
            </div>
            <div class="column is-4 margin-select-columns">
              <b-field>
                <b-radio
                  v-model="form.incident"
                  size="is-small"
                  native-value="sissen"
                >
                  Sissen
                </b-radio>
              </b-field>
            </div>
            <div class="column is-4 margin-select-columns">
              <b-field>
                <b-radio
                  v-model="form.incident"
                  size="is-small"
                  native-value="uitschelden"
                >
                  Uitschelden
                </b-radio>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4 margin-select-columns">
              <b-field>
                <b-radio
                  v-model="form.incident"
                  size="is-small"
                  native-value="volgen"
                >
                  Volgen
                </b-radio>
              </b-field>
            </div>
            <div class="column is-4 margin-select-columns">
              <b-field>
                <b-radio
                  v-model="form.incident"
                  size="is-small"
                  native-value="aanraken"
                >
                  Aanraken
                </b-radio>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-4 margin-select-columns">
              <b-field
                :type="{ 'is-danger': validation.incident }"
                :message="validation.incident"
              >
                <b-radio
                  v-model="form.incident"
                  size="is-small"
                  native-value="anders"
                >
                  Anders
                </b-radio>
              </b-field>
            </div>
          </div>

          <b-field
            v-if="form.incident === 'anders'"
            :type="{ 'is-danger': validation.incident_anders }"
            :message="validation.incident_anders"
          >
            <b-input
              id="incident_anders"
              custom-class="backgroundGrey"
              type="incident_anders"
              placeholder="Wat is er gebeurd"
              v-model="form.incident_anders"
            ></b-input>
          </b-field>

          <div class="title is-5">Beschrijving van de situatie</div>

          <b-field
            :type="{ 'is-danger': validation.incident_omschrijving }"
            :message="validation.incident_omschrijving"
          >
            <textarea
              v-model="form.incident_omschrijving"
              class="textarea backgroundGrey"
              rows="8"
            ></textarea>
          </b-field>

          <div class="title is-5">Wanneer is dit gebeurd?</div>

          <div class="columns">
            <div class="column">
              <b-field
                id="datum_incident"
                label="Datum"
                :type="{ 'is-danger': validation.datum_incident }"
                :message="{
                  [validation.datum_incident]: validation.datum_incident,
                }"
              >
                <b-datepicker
                  v-model="form.datum_incident"
                  locale="nl-NL"
                  placeholder="Selecteer datum van incident"
                  icon="calendar"
                  :first-day-of-week="1"
                  editable
                  :mobile-native="false"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column">
              <b-field
                id="tijd_incident"
                label="Tijd"
                :type="{ 'is-danger': validation.tijd_incident }"
                :message="{
                  [validation.tijd_incident]: validation.tijd_incident,
                }"
              >
                <b-timepicker
                  v-model="form.tijd_incident"
                  placeholder="Selecteer tijdstip van incident"
                  icon="clock"
                  hour-format="24"
                  locale="nl-NL"
                  :increment-minutes="15"
                  :mobile-native="false"
                >
                </b-timepicker>
              </b-field>
            </div>
          </div>

          <div class="title is-5">Locatie:</div>

          <b-field
            :type="{ 'is-danger': validation.gemeente }"
            :message="validation.gemeente"
          >
            <b-autocomplete
              id="gemeente"
              v-model="form.gemeente"
              :data="filteredGemeentes"
              placeholder="Gemeente"
              clearable
              :keep-first="true"
              :open-on-focus="true"
              @select="(option) => (selected = option)"
            >
              <template #empty>Gemeente onbekend</template>
            </b-autocomplete>
            <!-- <b-input
              id="gemeente"
              custom-class="backgroundGrey"
              placeholder="Gemeente"
              v-model="form.gemeente"
            ></b-input> -->
          </b-field>

          <b-field
            :type="{ 'is-danger': validation.straat }"
            :message="validation.straat"
          >
            <b-input
              id="straat"
              custom-class="backgroundGrey"
              type="straat"
              placeholder="Straat/locatie"
              v-model="form.straat"
            ></b-input>
          </b-field>

          <div class="columns" style="margin-bottom: 0px">
            <div class="column is-4">
              <b-field
                :type="{ 'is-danger': validation.postcode }"
                :message="validation.postcode"
              >
                <b-input
                  id="postcode"
                  custom-class="backgroundGrey"
                  type="postcode"
                  placeholder="Postcode"
                  v-model="form.postcode"
                ></b-input>
              </b-field>
            </div>
            <div class="column">
              <b-field
                custom-class="has-text-danger"
                :type="{ 'is-danger': validation.plaats }"
                :message="validation.plaats"
              >
                <b-input
                  id="plaats"
                  type="plaats"
                  placeholder="Plaats"
                  v-model="form.plaats"
                ></b-input>
              </b-field>
            </div>
          </div>

          <b-field
            id="locatie_omschrijving"
            :type="{ 'is-danger': validation.locatie_omschrijving }"
            :message="validation.locatie_omschrijving"
          >
            <textarea
              v-model="form.locatie_omschrijving"
              class="textarea"
              placeholder="Ter hoogte waarvan? (optioneel) bijvoorbeeld: centraal station"
              rows="3"
            ></textarea>
          </b-field>

          <b-field
            label="Hoe bent u bij ons terecht gekomen?"
            :type="{ 'is-danger': validation.hoe_kent_melder_vizier }"
            :message="validation.hoe_kent_melder_vizier"
          >
            <b-input
              id="hoe_kent_melder_vizier"
              title=""
              custom-class="backgroundGrey"
              type="hoe_kent_melder_vizier"
              placeholder="Website / gemeente / socialmedia..."
              v-model="form.hoe_kent_melder_vizier"
            ></b-input>
          </b-field>

          <b-field>
            <b-checkbox
              id="akkoordprivacyverklaring"
              v-model="akkoordprivacyverklaring"
              >Ik ga akkoord met de

              <a
                href="https://www.meldseksueleintimidatie.nl/wp-content/uploads/2020/12/Privacyreglement-Vizier-2020.pdf"
                target="_blank"
                rel="noopener noreferrer"
                >privacyverklaring</a
              >.
            </b-checkbox>
          </b-field>

          <div class="has-text-centered">
            <button
              :disabled="!akkoordprivacyverklaring"
              :class="[
                'button is-rounded is-medium pinkButton',
                {
                  'is-loading': ajaxloading.formulier_andere_gemeente_versturen,
                },
              ]"
              @click="submitForm()"
            >
              <span class="button-text-padding">Melden</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        meldingvoor: "melding gemaakt door persoon zelf",
        anoniem: false,
        gebruik_melding_communicatie: false,
        contact_opnemen: false,
        gemeente: "",
        telefoonnummer: "",
      },
      akkoordprivacyverklaring: false,
    };
  },
  computed: {
    gemeenten() {
      return this.$store.getters["meldformulieren/gemeenten"];
    },
    filteredGemeentes() {
      if (!this.gemeenten.length) {
        return this.gemeenten;
      }

      if (!this.form.gemeente) {
        return this.gemeenten;
      } else {
        return this.gemeenten.filter((option) => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.form.gemeente.toLowerCase()) >= 0
          );
        });
      }
    },
  },
  methods: {
    submitForm() {
      this.$store
        .dispatch(
          "meldformulieren/submitFormSeksueleIntimidatieAndereGemeente",
          this.form
        )

        .then(() => {
          this.$router.push({
            name: "meldingsuccess",
            query: { redirect: "https://www.meldseksueleintimidatie.nl/" },
          });
        })
        .catch((error) => {
          const element = document.getElementById(
            Object.keys(error.response.data.errors)[0]
          );
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        });
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (!this.form.contact_opnemen) {
          this.form.telefoonnummer = "";
          delete this.form.email;
        }
      },
    },
  },
  mounted() {},
};
</script>
