import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import state from "./state";
import * as mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";

import auth from "@/store/auth";
import gebruikers from "@/store/gebruikers";
import registraties from "@/store/registraties";
import logboek from "@/store/logboek";
import rapportages from "@/store/rapportages";
import meldformulieren from "@/store/meldformulieren";

export default new Vuex.Store({
  modules: {
    auth,
    gebruikers,
    registraties,
    logboek,
    rapportages,
    meldformulieren
  },
  state,
  mutations,
  getters,
  actions
});
