<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}
</style>

<template>
  <div>
    <div class="users-title">Registratie toevoegen</div>
    <form @submit.prevent="registratieAanmaken()">
      <br />
      <div class="colums">
        <div class="column"></div>
        <div class="column"></div>
      </div>
      <div class="title has-text-centered">Gegevens melder</div>
      <div class="columns">
        <div class="column is-6">
          <b-field
            id="datum_melding"
            label="Selecteer datum van melding"
            :type="{ 'is-danger': validation.datum_melding }"
            :message="{
              [validation.datum_melding]: validation.datum_melding,
            }"
          >
            <b-datepicker
              size="is-small"
              v-model="form.datum_melding"
              locale="nl-NL"
              rounded
              placeholder="Selecteer datum van melding"
              icon="calendar"
              :first-day-of-week="1"
            >
            </b-datepicker>
          </b-field>

          <b-field
            label="Bron melding"
            :type="{ 'is-danger': validation.bron }"
            :message="{ [validation.bron]: validation.bron }"
            id="bron"
          >
            <b-select
              size="is-small"
              v-if="settings.registraties"
              placeholder="Selecteer een bron"
              v-model="form.bron"
              rounded
              expanded
            >
              <option
                v-for="bron of settings.registraties.bron"
                :key="bron"
                :value="bron"
              >
                {{ hoofdletter(bron) }}
              </option>
            </b-select>
          </b-field>

          <section id="meldingvoor" v-if="settings.registraties">
            <div
              class="field"
              v-for="melding of settings.registraties.melder"
              :key="melding"
            >
              <b-radio
                v-model="form.meldingvoor"
                size="is-small"
                :native-value="melding"
              >
                {{ hoofdletter(melding) }}
              </b-radio>
            </div>
          </section>

          <hr />

          <div class="field" id="anoniem">
            <b-checkbox v-model="form.anoniem"> Anonieme melding </b-checkbox>
          </div>
          <div class="field" id="contact_opnemen">
            <b-checkbox v-model="form.contact_opnemen">
              Neem contact met mij op
            </b-checkbox>
          </div>
          <div class="field" id="gebruik_melding_communicatie">
            <b-checkbox v-model="form.gebruik_melding_communicatie">
              Toestemming voor gebruik melding voor communicatie
            </b-checkbox>
          </div>
        </div>

        <div class="column is-6">
          <b-field
            id="geslacht"
            label="Geslacht"
            :type="{ 'is-danger': validation.geslacht }"
            :message="{ [validation.geslacht]: validation.geslacht }"
          >
            <b-select
              size="is-small"
              v-if="settings.registraties"
              placeholder="Selecteer een geslacht"
              v-model="form.geslacht"
              rounded
              expanded
            >
              <option
                v-for="geslacht of settings.registraties.geslacht"
                :key="geslacht"
                :value="geslacht"
              >
                {{ hoofdletter(geslacht) }}
              </option>
            </b-select>
          </b-field>
          <span v-if="!form.anoniem">
            <b-field
              label="Naam"
              :type="{ 'is-danger': validation.naam }"
              :message="{ [validation.naam]: validation.naam }"
            >
              <b-input
                size="is-small"
                v-model="form.naam"
                rounded
                placeholder="Naam van melder"
                id="naam"
              ></b-input>
            </b-field>

            <b-field
              id="telefoonnummer"
              size="is-small"
              label="Telefoonnummer"
              :type="{ 'is-danger': validation.telefoonnummer }"
              :message="{
                [validation.telefoonnummer]: validation.telefoonnummer,
              }"
            >
              <b-input
                size="is-small"
                v-model="form.telefoonnummer"
                rounded
                placeholder="Telefoonnummer van melder"
              ></b-input>
            </b-field>

            <b-field
              id="email"
              label="Email"
              :type="{ 'is-danger': validation.email }"
              :message="{ [validation.email]: validation.email }"
            >
              <b-input
                size="is-small"
                v-model="form.email"
                rounded
                placeholder="Emailadres"
              ></b-input>
            </b-field>

            <b-field
              id="leeftijd"
              label="Leeftijd"
              :type="{ 'is-danger': validation.leeftijd }"
              :message="{ [validation.leeftijd]: validation.leeftijd }"
            >
              <b-input
                size="is-small"
                v-model="form.leeftijd"
                rounded
                placeholder="Leeftijd in jaren"
              ></b-input>
            </b-field>
          </span>
        </div>
      </div>

      <hr />
      <div class="title has-text-centered">Gegevens Incident</div>

      <div class="columns">
        <div class="column">
          <b-field
            id="incident"
            label="Incident"
            :type="{ 'is-danger': validation.incident }"
            :message="{ [validation.incident]: validation.incident }"
          >
            <b-select
              size="is-small"
              v-if="settings.registraties"
              placeholder="Selecteer een incident"
              v-model="form.incident"
              rounded
              expanded
            >
              <option
                v-for="incident of settings.registraties.incident"
                :key="incident"
                :value="incident"
              >
                {{ hoofdletter(incident) }}
              </option>
            </b-select>
          </b-field>

          <b-field
            v-if="form.incident === 'anders'"
            id="incident_anders"
            label="Omschrijving anders"
            :type="{ 'is-danger': validation.incident_anders }"
            :message="{
              [validation.incident_anders]: validation.incident_anders,
            }"
          >
            <b-input
              size="is-small"
              v-model="form.incident_anders"
              rounded
              placeholder="Omschrijving type incident"
            ></b-input>
          </b-field>

          <b-field
            id="type_incident"
            label="Type Incident"
            :type="{ 'is-danger': validation.type_incident }"
            :message="{ [validation.type_incident]: validation.type_incident }"
          >
            <b-select
              size="is-small"
              v-if="settings.registraties"
              placeholder="Selecteer het type incident"
              v-model="form.type_incident"
              rounded
              expanded
            >
              <option
                v-for="type_incident of settings.registraties.type_incident"
                :key="type_incident"
                :value="type_incident"
              >
                {{ hoofdletter(type_incident) }}
              </option>
            </b-select>
          </b-field>

          <b-field
            id="incident_omschrijving"
            label="Omschrijving van het incident"
            :type="{ 'is-danger': validation.incident_omschrijving }"
            :message="{
              [validation.incident_omschrijving]:
                validation.incident_omschrijving,
            }"
          >
            <b-input
              v-model="form.incident_omschrijving"
              type="textarea"
              maxlength="10000"
              placeholder="Omschrijving van het incident"
            ></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field
            id="datum_incident"
            label="Selecteer datum van incident"
            :type="{ 'is-danger': validation.datum_incident }"
            :message="{
              [validation.datum_incident]: validation.datum_incident,
            }"
          >
            <b-datepicker
              size="is-small"
              v-model="form.datum_incident"
              locale="nl-NL"
              rounded
              placeholder="Selecteer datum van incident"
              icon="calendar"
              :first-day-of-week="1"
            >
            </b-datepicker>
          </b-field>

          <b-field
            id="tijd_incident"
            label="Selecteer tijdstip van incident"
            :type="{ 'is-danger': validation.tijd_incident }"
            :message="{
              [validation.tijd_incident]: validation.tijd_incident,
            }"
          >
            <b-timepicker
              size="is-small"
              v-model="form.tijd_incident"
              rounded
              placeholder="Selecteer tijdstip van incident"
              icon="clock"
              hour-format="24"
              locale="nl-NL"
              :incrementMinutes="5"
            >
            </b-timepicker>
          </b-field>

          <b-field
            id="postcode"
            label="Postcode"
            :type="{ 'is-danger': validation.postcode }"
            :message="{ [validation.postcode]: validation.postcode }"
          >
            <b-input
              size="is-small"
              v-model="form.postcode"
              rounded
              placeholder="Postcode"
            ></b-input>
          </b-field>

          <b-field
            id="plaats"
            label="Plaats"
            :type="{ 'is-danger': validation.plaats }"
            :message="{ [validation.plaats]: validation.plaats }"
          >
            <b-input
              size="is-small"
              v-model="form.plaats"
              rounded
              placeholder="Plaats"
            ></b-input>
          </b-field>

          <b-field
            id="gemeente"
            label="Gemeente"
            :type="{ 'is-danger': validation.gemeente }"
            :message="{ [validation.gemeente]: validation.gemeente }"
          >
            <b-autocomplete
              @input="clearWijkOnInputGemeente()"
              rounded
              size="is-small"
              v-model="form.gemeente"
              :data="filteredGemeentes"
              placeholder="Gemeente"
              clearable
              :keep-first="true"
              :open-on-focus="true"
              @select="(option) => (selected = option)"
            >
              <template #empty>Gemeente onbekend</template>
            </b-autocomplete>
            <!-- <b-input
              id="gemeente"
              custom-class="backgroundGrey"
              placeholder="Gemeente"
              v-model="form.gemeente"
            ></b-input> -->
          </b-field>

          <b-field
            id="straat"
            label="Straat/Locatie"
            :type="{ 'is-danger': validation.straat }"
            :message="{ [validation.straat]: validation.straat }"
          >
            <b-input
              size="is-small"
              v-model="form.straat"
              rounded
              placeholder="Straatnaam"
            ></b-input>
          </b-field>

          <b-field
            v-if="checkIfWijkenGemeente()"
            id="wijk"
            label="Wijk"
            :type="{ 'is-danger': validation.wijk }"
            :message="validation.wijk"
          >
            <b-autocomplete
              v-model="form.wijk"
              :data="filteredWijken"
              placeholder="Wijk"
              clearable
              rounded
              size="is-small"
              :keep-first="true"
              :open-on-focus="true"
              @select="(option) => (selected = option)"
            >
              <template #empty>Wijk onbekend</template>
            </b-autocomplete>
            <!-- <b-input
              id="gemeente"
              custom-class="backgroundGrey"
              placeholder="Gemeente"
              v-model="form.gemeente"
            ></b-input> -->
          </b-field>

          <b-field
            id="locatie_omschrijving"
            label="Ter hoogte van"
            :type="{ 'is-danger': validation.locatie_omschrijving }"
            :message="{
              [validation.locatie_omschrijving]:
                validation.locatie_omschrijving,
            }"
          >
            <b-input
              size="is-small"
              v-model="form.locatie_omschrijving"
              rounded
              placeholder="Locatie omschrijving"
            ></b-input>
          </b-field>
        </div>
      </div>

      <div class="field" id="andere_instanties">
        <b-checkbox v-model="form.andere_instanties">
          In contact geweest met andere organisaties
        </b-checkbox>
      </div>

      <b-field
        id="dader_informatie"
        label="Daderinformatie"
        :type="{ 'is-danger': validation.dader_informatie }"
        :message="{
          [validation.dader_informatie]: validation.dader_informatie,
        }"
      >
        <b-input
          v-model="form.dader_informatie"
          type="textarea"
          maxlength="10000"
          placeholder="Aantal daders, wat is er gezegd of gebeurd"
        ></b-input>
      </b-field>

      <b-field
        id="vervolgstappen"
        label="Gewenste maatregelen"
        :type="{ 'is-danger': validation.vervolgstappen }"
        :message="{
          [validation.vervolgstappen]: validation.vervolgstappen,
        }"
      >
        <b-input
          v-model="form.vervolgstappen"
          type="textarea"
          maxlength="10000"
          placeholder="Gewenste maatregelen"
        ></b-input>
      </b-field>

      <b-field
        id="ervaring"
        label="Ervaring van incident"
        :type="{ 'is-danger': validation.ervaring }"
        :message="{ [validation.ervaring]: validation.ervaring }"
      >
        <b-select
          size="is-small"
          v-if="settings.registraties"
          placeholder="Selecteer de ervaring van de intimidatie"
          v-model="form.ervaring"
          rounded
          expanded
        >
          <option
            v-for="ervaring of settings.registraties.ervaring"
            :key="ervaring"
            :value="ervaring"
          >
            {{ hoofdletter(ervaring) }}
          </option>
        </b-select>
      </b-field>

      <b-field
        id="hoe_kent_melder_vizier"
        label="Hoe is melder bij Vizier terecht gekomen?"
        :type="{ 'is-danger': validation.hoe_kent_melder_vizier }"
        :message="{
          [validation.hoe_kent_melder_vizier]:
            validation.hoe_kent_melder_vizier,
        }"
      >
        <b-input
          size="is-small"
          v-model="form.hoe_kent_melder_vizier"
          rounded
          placeholder="Hoe is melder bij Vizier terecht gekomen?"
        ></b-input>
      </b-field>
      <br />

      <hr />
      <div class="title has-text-centered">Gegevens Consulent</div>

      <div class="columns">
        <div class="column is-6">
          <b-field
            id="notitie"
            :label="'Notitie ' + user.name"
            :type="{ 'is-danger': validation.notitie }"
            :message="{
              [validation.notitie]: validation.notitie,
            }"
          >
            <b-input
              v-model="form.notitie"
              type="textarea"
              maxlength="10000"
              placeholder="Notitie consulent"
            ></b-input>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field
            id="tijdregistratie"
            label="Tijdregistratie in minuten"
            :type="{ 'is-danger': validation.tijdregistratie }"
            :message="{
              [validation.tijdregistratie]: validation.tijdregistratie,
            }"
          >
            <b-input
              size="is-small"
              v-model="form.tijdregistratie"
              rounded
              placeholder="Minuten"
            ></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field
            id="afhandeling"
            label="Afhandeling"
            :type="{ 'is-danger': validation.afhandeling }"
            :message="{ [validation.afhandeling]: validation.afhandeling }"
          >
            <b-select
              size="is-small"
              v-if="settings.registraties"
              placeholder="Selecteer afhandeling"
              v-model="form.afhandeling"
              rounded
              expanded
            >
              <option
                v-for="afhandeling of settings.registraties.afhandeling"
                :key="afhandeling"
                :value="afhandeling"
              >
                {{ hoofdletter(afhandeling) }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field
            id="status"
            label="Status"
            :type="{ 'is-danger': validation.status }"
            :message="{ [validation.status]: validation.status }"
          >
            <b-select
              size="is-small"
              v-if="settings.registraties"
              placeholder="Selecteer het type incident"
              v-model="form.status"
              rounded
              expanded
            >
              <option
                v-for="status of settings.registraties.statussen"
                :key="status"
                :value="status"
              >
                {{ hoofdletter(status) }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <br />

      <b-button
        native-type="submit"
        :loading="ajaxloading.registratieaanmaken"
        type="is-primary is-rounded"
        >Registratie aanmaken</b-button
      >&nbsp;

      <b-button type="is-rounded" @click="$router.go(-1)">Annuleren</b-button>
    </form>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        anoniem: true,
        contact_opnemen: false,
        gebruik_melding_communicatie: false,
        meldingvoor: "melding gemaakt door persoon zelf",
        status: "in behandeling",
        gemeente: "",
        wijk: "",
      },
      wijken: [],
    };
  },
  computed: {
    settings() {
      return this.$store.getters["auth/settings"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
    filteredWijken() {
      return this.wijken.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.form.wijk.toLowerCase()) >= 0
        );
      });
    },
    gemeenten() {
      return this.$store.getters["meldformulieren/gemeenten"];
    },
    filteredGemeentes() {
      if (!this.gemeenten.length) {
        return this.gemeenten;
      }

      if (!this.form.gemeente) {
        return this.gemeenten;
      } else {
        return this.gemeenten.filter((option) => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.form.gemeente.toLowerCase()) >= 0
          );
        });
      }
    },
  },
  methods: {
    filterWijken() {
      const settings = this.$store.getters["auth/settings"];
      if (settings && this.checkIfWijkenGemeente()) {
        this.wijken =
          settings.registraties.wijken[this.form.gemeente.toLowerCase()];
      } else {
        this.wijken = [];
      }
    },
    checkIfWijkenGemeente() {
      const settings = this.$store.getters["auth/settings"];

      if (!settings) {
        return false;
      }

      if (!this.form.gemeente) {
        return false;
      }

      if (this.form.gemeente.toLowerCase() in settings.registraties.wijken) {
        return true;
      }
    },
    registratieAanmaken() {
      this.$store
        .dispatch("registraties/registratieAanmaken", this.form)
        .then(() => {
          this.$store.dispatch("createFlashBanner", {
            message: "Registratie aangemaakt.",
            timeout: 3,
          });
          this.$router.push({ name: "registraties-index" });
        })
        .catch((error) => {
          const element = document.getElementById(
            Object.keys(error.response.data.errors)[0]
          );
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        });
    },
    clearWijkOnInputGemeente() {
      if (!this.wijken.includes(this.form.wijk)) {
        this.form.wijk = "";
      }
    },
  },
  watch: {
    settings: function (val) {
      this.filterWijken();
    },
    form: {
      deep: true,

      // We have to move our method to a handler field
      handler(value) {
        this.filterWijken();
      },
    },
  },
  mounted() {},
};
</script>
