<template>
  <div>
    <div class="box container">
      <b-loading v-model="ajaxloading.gettingregistratiestats" />
      <b-field label="Registraties">
        <table style="width:80%">
          <tr>
            <td>Registraties in behandeling</td>
            <td>{{ registratieStats.registraties_in_behandeling }}</td>
          </tr>
          <tr>
            <td>Afgehandelde registraties</td>
            <td>{{ registratieStats.afgehandelde_registraties }}</td>
          </tr>
          <tr>
            <td>Totale registraties</td>
            <td>{{ registratieStats.totale_registraties }}</td>
          </tr>
        </table>
      </b-field>
      <br />
      <div class="columns">
        <div
          class="column tile-button"
          @click="$router.push({ name: 'registratie-aanmaken' })"
        >
          <div class="">Nieuwe registratie</div>
        </div>
        <div
          class="column tile-button"
          @click="$router.push({ name: 'registraties-index' })"
        >
          <p>Alle registraties</p>
        </div>
        <!-- <div class="column tile-button">
          <p>Rapportages</p>
        </div> -->
        <div
          class="column tile-button"
          @click="tileClicked('gebruikers-index')"
        >
          <p>
            Gebruikers
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    registratieStats() {
      return this.$store.getters["rapportages/registratieStats"];
    }
  },
  methods: {
    tileClicked(routeName) {
      this.$router.push({ name: routeName });
    }
  },
  mounted() {
    this.customMounted("rapportages/getRegistratieStats");
  }
};
</script>
