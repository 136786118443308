import axios from "axios";
import router from "@/router";
import store from "@/store";

export const axiosrequest = axios.interceptors.request.use(config => {
  store.dispatch("clearValidationErrors");
  return config;
});

export const axiosresponse = axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // if (error.message === "Geannuleerd") {
    //   return Promise.reject(error);
    // }
    // if (error.message === "Your email address is not verified") {
    //   return Promise.reject(error);
    // }
    if (error.response) {
      if (error.response.status === 401) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        store.dispatch("auth/clearLoginData");
        router.replace({ name: "inloggen" });
        store.dispatch("createFlashBanner", {
          message: "U bent niet ingelogd. Log in om verder te gaan.",
          timeout: 5
        });
        return Promise.reject(error);
      }
      if (error.response.status === 422) {
        //validatie error van geposte form data
        store.dispatch("setValidationErrors", error.response.data.errors);
        return Promise.reject(error);
      }
      if (error.response.status === 403) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        store.dispatch("createFlashBanner", {
          message: "Deze actie is niet toegestaan.",
          timeout: 3
        });
        return Promise.reject(error);
      }
      if (error.response.status === 404) {
        router.replace({ name: "404" });
        return Promise.reject(error);
      }
      if (error.response.status === 503) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        store.dispatch("createFlashBanner", {
          message:
            "De applicatie wordt moment ge-update. Probeer het later opnieuw.",
          timeout: 10
        });
        return Promise.reject(error);
      }
      //   //apparaat niet gevalideerd
      //   if (error.response.status === 444) {
      //     router.replace({ name: "onbekendApparaat" });
      //     return Promise.reject(error);
      //   }
      //   //apparaat validatie cookie niet bevestigd via email
      //   if (error.response.status === 445) {
      //     router.replace({ name: "apparaatEmail" });
      //     return Promise.reject(error);
      //   }
      //   //apparaat validatielink is niet geldig
      //   if (error.response.status === 446) {
      //     //wordt afgehandeld in component
      //     return Promise.reject(error);
      //   }
      //   //apparaat cookie is verlopen
      //   if (error.response.status === 447) {
      //     router.replace({ name: "apparaatEmail" });
      //     return Promise.reject(error);
      //   }

      //   //apparaat validatielink is verlopen
      //   if (error.response.status === 448) {
      //     //wordt afgehandeld in component
      //     return Promise.reject(error);
      //   }

      //   //csfr token niet meer geldig, refreshing pagina
      //   if (error.response.status === 419) {
      //     store.dispatch("auth/clearLoginData");
      //     router.replace({ name: "login" });
      //     location.reload();
      //     return Promise.reject(error);
      //   }
      return Promise.reject(error);
    }
  }
);
