export const getLoginLogs = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-loginlogs";
  data.method = "GET";
  data.loading = "getloginlogs";
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setLoginLogs", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getActionLogs = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-actionlogs";
  data.method = "GET";
  data.loading = "getactionlogs";
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setActionLogs", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getInzageLogs = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-inzagelogs";
  data.method = "GET";
  data.loading = "getinzagelogs";
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setInzageLogs", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const getUsers = function({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-users-for-log";
  data.method = "GET";
  data.loading = "getactionlogs";
  return dispatch("axiosApi", data, { root: true })
    .then(response => {
      commit("setUsers", response.data);
      return Promise.resolve(response);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

// export const registratieAanmaken = function({ dispatch, commit }, payload) {
//   const data = {};
//   data.url = "registratie-aanmaken";
//   data.method = "GET";
//   data.loading = "registratieaanmaken";
//   data.data = payload;
//   return dispatch("axiosApi", data, { root: true })
//     .then(response => {
//       commit("setNewRegistratie", response.data);
//       return Promise.resolve(response);
//     })
//     .catch(error => {
//       return Promise.reject(error);
//     });
// };
