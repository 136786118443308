<template>
  <div>
    <div class="box container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>

<style scoped>
.centered-box {
  min-width: 800px;
}
</style>
