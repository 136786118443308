export const setUser = function(state, payload) {
  state.user = payload;
};

export const setToken = function(state, payload) {
  state.token = payload;
};

export const setLoginToken = function(state, payload) {
  state.loginToken = payload;
};

export const setQueuedActions = function(state, payload) {
  if (typeof payload === "string") {
    state.queuedActions.push(payload);
  }
  if (typeof payload === "object") {
    state.queuedActions.push(...payload);
  }
};

export const removeActionFromQueue = function(state) {
  state.queuedActions.splice(0, 1);
};

export const clearQueuedActions = function(state) {
  state.queuedActions = [];
};

export const setSettings = function(state, payload) {
  state.settings = payload;
};
