<template>
  <div>
    <div class="box has-text-centered">
      Hartelijk dank voor uw melding. Wij gaan er mee aan de slag en nemen,
      indien gewenst, zo spoedig mogelijk contact met u op.
      <br />
      <br />
      U wordt binnen {{ timeTillReturn }} {{ calculateSeconden }} doorgestuurd
      naar de homepagina
      <br />
      <br />
      <div class="button is-rounded is-primary" @click="returnToHomePagina()">
        Naar Homepagina
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      interval: "",
      timeTillReturn: 10
    };
  },
  computed: {
    calculateSeconden() {
      if (this.timeTillReturn === 1) {
        return "seconde";
      } else {
        return "seconden";
      }
    }
  },
  methods: {
    returnToHomePagina() {
      clearInterval(this.interval);
      window.location.href = this.$route.query.redirect;
    },
    returnToHome() {
      clearInterval(this.interval);
      if (
        this.$router.currentRoute.name === "meldingsuccess" &&
        this.$route.query.redirect
      ) {
        window.location.href = this.$route.query.redirect;
      }
    }
  },
  mounted() {
    setTimeout(
      function() {
        this.returnToHome();
      }.bind(this),
      10000
    );
    this.interval = setInterval(
      function() {
        this.timeTillReturn--;
      }.bind(this),
      1000
    );
  }
};
</script>
